import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';


import Mint1 from "./pages/Mint1";
import Mint2 from "./pages/Mint2";
import Mint2a from "./pages/Mint2a";
import Mint3 from "./pages/Mint3";

function App() {
  const { chains, publicClient } = configureChains(
    [arbitrum],
    [
      infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID }),
      publicProvider()
    ]
  );
  
  const { connectors } = getDefaultWallets({
    appName: 'SkyScraper',
    projectId: 'f4df8192de27080a3b315ca4cba47256',
    chains
  });
  
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Router>
          <Fragment>
            <Routes>
              <Route path="/" element={<Mint1 />} />
              <Route path="/mint2" element={<Mint2 />} />
              <Route path="/mint2a" element={<Mint2a />} />
              <Route path="/mint3" element={<Mint3 />} />
            </Routes>
          </Fragment>
        </Router>
      </RainbowKitProvider>
    </WagmiConfig>
    
  );
}

export default App;
